import { Injectable, WritableSignal, signal } from "@angular/core";
import { HeaderState } from "./backoffice-header.component";

@Injectable({
  providedIn: "root"
})
export class BackofficeHeader {
  public state: WritableSignal<HeaderState> = signal({});

  public setState(state: HeaderState): void {
    this.state.set(state);
  }

  public updateState(state: Partial<HeaderState>): void {
    this.state.update((current) => {
      return { ...current, ...state };
    });
  }
}
