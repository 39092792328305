import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef
} from "@angular/core";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { BackofficeHeader } from "./backoffice.header";

@Component({
  selector: "backoffice-header-configuration",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: []
})
export class BackofficeHeaderConfigurationComponent implements OnChanges, AfterViewInit {
  @Input()
  public title?: string | InlineTranslation;

  @Input()
  public subTitle?: string;

  @Input()
  public icon?: string;

  @Input()
  public backUrl?: string;

  @HostBinding("style.display")
  protected display = "none";

  @ContentChild("actions", { static: false, read: TemplateRef })
  private actions: TemplateRef<any>;

  constructor(private backofficeHeader: BackofficeHeader) {}

  public ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName in changes) {
        switch (propName) {
          case "title":
          case "subTitle":
          case "icon":
          case "actions":
            this.backofficeHeader.setState({
              actions: this.actions,
              title: this.title,
              subTitle: this.subTitle,
              icon: this.icon,
              backUrl: this.backUrl
            });
        }
      }
    }
  }

  public ngAfterViewInit(): void {
    this.backofficeHeader.setState({
      actions: this.actions,
      title: this.title,
      subTitle: this.subTitle,
      icon: this.icon,
      backUrl: this.backUrl
    });
  }
}
